import React, { useState } from 'react';
import HospitalIcon from '../assets/chat_03.png';
import LogoutIcon from '../assets/logout.png';
import './ChannelListContainer.css';

const SideBar = ({ logout }) => (
  <div className="channel-list__sidebar">
    <div className="channel-list__sidebar__icon1">
      <div className="icon1__inner">
        <img src={HospitalIcon} alt="Hospital" width="30" />
      </div>
    </div>
    <div className="channel-list__sidebar__icon2">
      <div className="icon1__inner" onClick={logout}>
        <img src={LogoutIcon} alt="Logout" width="30" />
      </div>
    </div>
  </div>
);

const CompanyHeader = () => (
  <div className="channel-list__header">
    <p className="channel-list__header__text">Al Mamzar Car Wash</p>
  </div>
);

const ChannelListContent = ({ onNavigate }) => {
  const [activeButton, setActiveButton] = useState('');

  const logout = () => {
    window.location.reload();
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    onNavigate(buttonName);
  };

  // Filtered list of tabs to show
  const tabs = [
    { name: 'ServiceTransaction', label: 'Sales' },
    { name: 'Expenses', label: 'Expenses' },
    { name: 'JournalEntries', label: 'Journal Entries' },
    { name: 'GeneralLedger', label: 'Ledgers' },
    { name: 'Invoices', label: 'Invoices' },
    { name: 'Payments', label: 'Payments' },
  ];

  return (
    <>
      <SideBar logout={logout} />
      <div className="channel-list__list__wrapper">
        <CompanyHeader />
        <div className="channel-list__buttons">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`channel-list__button ${activeButton === tab.name ? 'active' : ''}`}
              onClick={() => handleButtonClick(tab.name)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

const ChannelListContainer = ({ onNavigate }) => {
  const [toggleContainer, setToggleContainer] = useState(false);

  return (
    <>
      <div className="channel-list__container">
        <ChannelListContent onNavigate={onNavigate} />
      </div>

      <div
        className="channel-list__container-responsive"
        style={{ left: toggleContainer ? '0%' : '-89%', backgroundColor: 'var(--primary-color)' }}
      >
        <div
          className="channel-list__container-toggle"
          onClick={() => setToggleContainer((prev) => !prev)}
        ></div>
        <ChannelListContent onNavigate={onNavigate} />
      </div>
    </>
  );
};

export default ChannelListContainer;
