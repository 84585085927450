import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import './ServiceTransaction.css';

const ServiceTransaction = () => {
    const [customerType, setCustomerType] = useState('normal');
    const [customerName, setCustomerName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [address, setAddress] = useState('');
    const [vehicleType, setVehicleType] = useState('car');
    const [vehicleNo, setVehicleNo] = useState('');
    const [services, setServices] = useState([{ serviceId: '', servicePrice: '', customServiceName: false }]);
    const [date, setDate] = useState('');
    const [availableServices, setAvailableServices] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [driverName, setDriverName] = useState('');
    const [trnNo, setTrnNo] = useState('');
    const [driverNo, setDriverNo] = useState('');
const [notes, setNotes] = useState('');
const [companyOptions, setCompanyOptions] = useState([]); // List of companies
const [selectedCompany, setSelectedCompany] = useState(''); // Selected company ID
const [isNewCompany, setIsNewCompany] = useState(false); // Flag for "Others" option



    useEffect(() => {
        fetch('https://almamzarcarwash.com:3001/api/services')
            .then((response) => response.json())
            .then((data) => {
                setAvailableServices(data);
            })
            .catch((error) => console.error('Error fetching services:', error));
    }, []);

    const handleAddService = () => {
        setServices([...services, { serviceId: '', servicePrice: '', customServiceName: false }]);
    };

    const handleServiceIdChange = (index, event) => {
        const newServices = [...services];
        const selectedService = event.target.value;

        if (selectedService === 'Others') {
            newServices[index].customServiceName = true;
            newServices[index].serviceId = '';
        } else {
            newServices[index].serviceId = selectedService;
            newServices[index].customServiceName = false;
        }

        setServices(newServices);
    };

    const handleServicePriceChange = (index, event) => {
        const newServices = [...services];
        newServices[index].servicePrice = event.target.value;
        setServices(newServices);
    };

    const handleServiceNameInputChange = (index, event) => {
        const newServices = [...services];
        newServices[index].serviceId = event.target.value;
        setServices(newServices);
    };

    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const [isCustomVehicleType, setIsCustomVehicleType] = useState(false);
    
    // Fetch company details
    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await fetch('https://almamzarcarwash.com:3001/api/companiesinfo');
                if (!response.ok) {
                    throw new Error(`Failed to fetch company details. Status: ${response.status}`);
                }
                const data = await response.json();
                setCompanyOptions(data);
            } catch (error) {
                console.error('Error fetching company details:', error);
                alert(`Error fetching company details: ${error.message}`);
            }
        };

        fetchCompanyDetails();
    }, []);

    // Handle company selection
    const handleCompanyChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'Others') {
            setIsNewCompany(true);
            setCompanyName('');
            setContactNo('');
            setCustomerName(''); // Reset contact person
            setAddress('');
            setTrnNo(''); // Reset TRN No. for new companies
        } else {
            setIsNewCompany(false);
            const company = companyOptions.find((company) => company.id === parseInt(selectedValue));
            if (company) {
                setCompanyName(company.name);
                setContactNo(company.contactNo);
                setAddress(company.address);
                setCustomerName(company.contactPerson); 
                setTrnNo(company.trnNo || ''); // Populate TRN No.
            }
        }
        setSelectedCompany(selectedValue);
    };

    useEffect(() => {
        const fetchVehicleTypes = async () => {
            try {
                const response = await fetch('https://almamzarcarwash.com:3001/api/vehicleTypes');
                if (!response.ok) {
                    throw new Error(`Failed to fetch vehicle types. Status: ${response.status}`);
                }
                const data = await response.json();
                console.log("Vehicle types fetched successfully:", data); // Debugging log
                setVehicleTypeOptions(data);
            } catch (error) {
                console.error('Error fetching vehicle types:', error);
                alert(`Error fetching vehicle types: ${error.message}`); // Show error as alert
            }
        };

        fetchVehicleTypes();
    }, []);
  
    const handleVehicleTypeChange = (event) => {
        const selectedType = event.target.value;
    
        if (selectedType === 'Others') {
            setIsCustomVehicleType(true);
            setVehicleType('');
        } else {
            setIsCustomVehicleType(false);
            setVehicleType(selectedType);
        }
    };
    
    const handleVehicleTypeInputChange = (event) => {
        setVehicleType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Add custom services to the backend before submitting the transaction
        const updatedServices = await Promise.all(
            services.map(async (service) => {
                if (service.customServiceName) {
                    const response = await fetch('https://almamzarcarwash.com:3001/api/services/add', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ serviceName: service.serviceId }),
                    });
                    const result = await response.json();
                    if (!result.success) {
                        throw new Error(result.error || 'Failed to add custom service.');
                    }
                    return {
                        ...service,
                        serviceId: result.serviceId, // Use the newly created service ID
                        customServiceName: false,   // Mark as no longer custom
                    };
                }
                return service;
            })
        );
    

        const data = {
            customerType,
            customerName: customerType === 'normal' ? customerName : companyName,
            companyName: customerType === 'company' ? companyName : undefined,
            contactNo,
            address,
            vehicleType,
            vehicleNo,
            services: updatedServices, // Ensure services are updated for custom services
            date,
            paymentMethod,
            driverName, // Add driverName here
            driverNo,   // Add driverNo here
            notes,
            trnNo: customerType === 'company' ? trnNo : undefined, // Add TRN No. only for companies
        };
        try {
            const response = await fetch('https://almamzarcarwash.com:3001/api/serviceTransaction/add', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
    
            const result = await response.json();
            if (result.success) {
                alert('Data submitted successfully!');
    
                // Generate Invoice for Normal Customer
                if (customerType === 'normal') {
                    const doc = new jsPDF();
    
                    // Header Section
                    doc.setFontSize(15);
                    doc.setFont('helvetica', 'bold');
                    doc.text('Al-Mamzar Car Wash', 105, 20, { align: 'center' });
    
                    doc.setFontSize(12);
                    doc.setFont('helvetica', 'normal');
                    doc.text('Ajman, UAE', 105, 28, { align: 'center' });
                    doc.text('TRN: 1234567890', 105, 34, { align: 'center' });
                    doc.text('Tel: +971 XXXX XXXX', 105, 40, { align: 'center' });
    
                    // Draw line below header
                    doc.line(10, 45, 200, 45);
    
                    // Invoice Title
                    doc.setFontSize(16);
                    doc.setFont('helvetica', 'bold');
                    doc.text('INVOICE', 105, 55, { align: 'center' });
    
                    // Customer Details
                    doc.setFontSize(12);
                    doc.setFont('helvetica', 'normal');
                    doc.text(`Customer Name: ${customerName}`, 10, 70);
                    doc.text(`Contact No: ${contactNo}`, 10, 76);
                    doc.text(`Address: ${address}`, 10, 82);
                    doc.text(`Vehicle Type: ${vehicleType}`, 10, 88);
                    doc.text(`Vehicle No: ${vehicleNo}`, 10, 94);
                    doc.text(`Date: ${date}`, 150, 70);
                    doc.text(`Invoice No: ${Math.floor(Math.random() * 1000000)}`, 150, 76);
    
                    // Draw line below details
                    doc.line(10, 100, 200, 100);
    
                    // Table Headers
                    const headers = ['Sl. No', 'Service Name', 'Service Price (AED)'];
                    const colWidths = [15, 120, 55];
                    let startX = 10;
                    let startY = 110;
    
                    doc.setFont('helvetica', 'bold');
                    headers.forEach((header, index) => {
                        doc.text(header, startX, startY);
                        startX += colWidths[index];
                    });
    
                    // Table Rows
                    startY += 10;
                    doc.setFont('helvetica', 'normal');
                    let totalAmount = 0;
    
                    services.forEach((service, index) => {
                        let rowX = 10;
                        const rowY = startY + index * 10;
    
                        const rowData = [
                            String(index + 1),
                            service.customServiceName ? service.serviceId : availableServices.find(s => s.service_name === service.serviceId)?.service_name || '',
                            Number(service.servicePrice).toFixed(2),
                        ];
    
                        rowData.forEach((data, colIndex) => {
                            doc.text(data, rowX, rowY);
                            rowX += colWidths[colIndex];
                        });
    
                        // Draw line below each row
                        doc.line(10, rowY + 2, 200, rowY + 2);
    
                        totalAmount += parseFloat(service.servicePrice || 0);
                    });
    
                    // Total Amount Section
                    const taxAmount = (totalAmount * 0.05).toFixed(2);
                    const grandTotal = (totalAmount + parseFloat(taxAmount)).toFixed(2);
    
                    startY += services.length * 10 + 10;
                    doc.setFont('helvetica', 'bold');
                    doc.text('WITHOUT TAX (AED):', 120, startY);
                    doc.text(totalAmount.toFixed(2), 180, startY);
    
                    startY += 10;
                    doc.text('TAX AMOUNT (5%):', 120, startY);
                    doc.text(taxAmount, 180, startY);
    
                    startY += 10;
                    doc.text('TOTAL AMOUNT (AED):', 120, startY);
                    doc.text(grandTotal, 180, startY);
    
                    // Footer
                    startY += 20;
                    doc.setFont('helvetica', 'normal');
                    doc.text('Thank you for your business!', 105, startY, { align: 'center' });
    
                    // Save PDF
                    doc.save(`Invoice_${customerName}_${new Date().toISOString()}.pdf`);
                }
    
                // Reset form fields
                setCustomerType('normal');
                setCustomerName('');
                setCompanyName('');
                setContactNo('');
                setAddress('');
                setVehicleType('car');
                setVehicleNo('');
                setServices([{ serviceId: '', servicePrice: '', customServiceName: false }]);
                setDate('');
                setPaymentMethod('');
            } else {
                alert(`Failed to submit data: ${result.error}`);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            alert(`Error: ${error.message}`);
        }
    };
    
    
    return (
        <div className="main-container">
            <form onSubmit={handleSubmit}>
                <div className="service-transaction-container">
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="customerType"
                                value="normal"
                                checked={customerType === 'normal'}
                                onChange={() => {
                                    setCustomerType('normal');
                                    setPaymentMethod(''); // Reset payment method when switching customer type
                                }}
                            />
                            Normal Customer
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="customerType"
                                value="company"
                                checked={customerType === 'company'}
                                onChange={() => {
                                    setCustomerType('company');
                                    setPaymentMethod(''); // Reset payment method when switching customer type
                                }}
                            />
                            Company's Car
                        </label>
                    </div>
    
                    <div className="form-content">
    {customerType === 'normal' ? (
        <>
            <div className="form-group">
                <label>Customer Name:</label>
                <input
                    type="text"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Contact No:</label>
                <input
                    type="text"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Address:</label>
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
            </div>
           <div className="form-group">
    <label>Vehicle Type:</label>
    {isCustomVehicleType ? (
        <input
            type="text"
            placeholder="Enter vehicle type"
            value={vehicleType}
            onChange={handleVehicleTypeInputChange}
            required
        />
    ) : (
        <select
            value={vehicleType}
            onChange={handleVehicleTypeChange}
            required
        >
            <option value="">Select a vehicle type</option>
            {vehicleTypeOptions.map((type) => (
                <option key={type.vehicle_id} value={type.vehicle_type}>
                    {type.vehicle_type}
                </option>
            ))}
            <option value="Others">Others</option>
        </select>
    )}
</div>


            <div className="form-group">
                <label>Vehicle No:</label>
                <input
                    type="text"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Date:</label>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Payment Method:</label>
                <select
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    required
                >
                    <option value="">Select a payment method</option>
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                </select>
            </div>
            <div className="form-group" style={{ gridColumn: "1 / -1" }}>
                <label>Notes (Optional):</label>
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add any additional notes here..."
                    rows="4"
                    style={{ width: "100%" }}
                />
            </div>
        </>
    ) :(
        <>
            <div className="form-group">
                <label>Company Name:</label>
                {isNewCompany ? (
        // Render text input if "Others" is selected
        <input
            type="text"
            value={companyName}
            placeholder="Enter new company name"
            onChange={(e) => setCompanyName(e.target.value)}
            required
        />
    ) : (
        // Render dropdown for existing companies
        <select
            value={selectedCompany}
            onChange={handleCompanyChange}
            required
        >
            <option value="">Select a company</option>
            {companyOptions.map((company) => (
                <option key={company.id} value={company.id}>
                    {company.name}
                </option>
            ))}
            <option value="Others">Others</option>
        </select>
    )}
</div>

<div className="form-group">
    <label>Contact Person:</label>
    <input
        type="text"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        required
        disabled={!isNewCompany} // Disable if it's an existing company
    />
</div>
            <div className="form-group">
                <label>Contact No:</label>
                <input
                    type="text"
                    value={contactNo}
                    onChange={(e) => setContactNo(e.target.value)}
                    required
                    disabled={!isNewCompany}
                />
            </div>
            <div className="form-group">
            <label>TRN No:</label>
            <input
                type="text"
                value={trnNo}
                onChange={(e) => setTrnNo(e.target.value)}
                placeholder="Enter TRN No."
                required
                disabled={!isNewCompany}
            />
        </div>
            <div className="form-group">
                <label>Address:</label>
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    disabled={!isNewCompany}
                />
            </div>
            <div className="form-group">
                <label>Vehicle Type:</label>
                {isCustomVehicleType ? (
                    <input
                        type="text"
                        placeholder="Enter vehicle type"
                        value={vehicleType}
                        onChange={handleVehicleTypeInputChange}
                        required
                    />
                ) : (
                    <select
                        value={vehicleType}
                        onChange={handleVehicleTypeChange}
                        required
                    >
                        <option value="">Select a vehicle type</option>
                        {vehicleTypeOptions.map((type) => (
                            <option key={type.vehicle_id} value={type.vehicle_type}>
                                {type.vehicle_type}
                            </option>
                        ))}
                        <option value="Others">Others</option>
                    </select>
                )}
            </div>
            <div className="form-group">
                <label>Vehicle No:</label>
                <input
                    type="text"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Driver Name:</label>
                <input
                    type="text"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Driver No:</label>
                <input
                    type="text"
                    value={driverNo}
                    onChange={(e) => setDriverNo(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Date:</label>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Payment Method:</label>
                <select
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    required
                >
                    <option value="">Select a payment method</option>
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                    <option value="credit">Credit</option>
                </select>
            </div>
            <div className="form-group" style={{ gridColumn: "1 / -1" }}>
                <label>Notes (Optional):</label>
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add any additional notes here..."
                    rows="4"
                    style={{ width: "100%" }}
                />
            </div>
        </>
    )}
</div>


                    <table className="service-table">
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Service Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map((service, index) => (
                                <tr key={index}>
                                    <td>
                                        {service.customServiceName ? (
                                            <input
                                                type="text"
                                                placeholder="Enter service name"
                                                value={service.serviceId}
                                                onChange={(e) => handleServiceNameInputChange(index, e)}
                                                required
                                            />
                                        ) : (
                                            <select
                                                value={service.serviceId}
                                                onChange={(e) => handleServiceIdChange(index, e)}
                                                required
                                            >
                                                <option value="">Select a service</option>
                                                {availableServices.map((availableService) => (
                                                    <option key={availableService.id} value={availableService.service_name}>
                                                        {availableService.service_name}
                                                    </option>
                                                ))}
                                                <option value="Others">Others</option>
                                            </select>
                                        )}
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={service.servicePrice}
                                            onChange={(e) => handleServicePriceChange(index, e)}
                                            required
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
    
                    <div className="button-container">
                        <button type="button" className="add-expense-btn" onClick={handleAddService}>
                            Add another service
                        </button>
                    </div>
    
                    <div className="button-container">
                        <button type="submit" className="add-expense-btn">
                            Save and Print
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
    
};

export default ServiceTransaction;
