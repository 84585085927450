// TrialBalance.jsx
import React from 'react';
import './TrialBalance.css'; // Import the CSS file

const TrialBalance = () => {
  // Hardcoded data for the trial balance
  const trialBalanceData = [
    { account: "Bank", debit: 45000, credit: 2000 },
    { account: "Cash", debit: 0, credit: 16000 },
    { account: "Equity", debit: 0, credit: 20000 },
    { account: "Expense", debit: 3000, credit: 0 },
    { account: "Sales", debit: 0, credit: 10000 },
  ];

  // Calculate totals
  const totalDebit = trialBalanceData.reduce((acc, item) => acc + item.debit, 0);
  const totalCredit = trialBalanceData.reduce((acc, item) => acc + item.credit, 0);

  return (
    <div className="trial-balance">
      <h1>Trial Balance</h1>
      <h2>Auto generating the Trial Balance from the data</h2>
      <table className="trial-balance-table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Sum of Debit</th>
            <th>Sum of Credit</th>
            <th>Sum of Balance</th>
          </tr>
        </thead>
        <tbody>
          {trialBalanceData.map((item, index) => {
            const balance = item.debit - item.credit; // Calculate balance for each account
            return (
              <tr key={index}>
                <td>{item.account}</td>
                <td>{item.debit === 0 ? '-' : item.debit.toLocaleString()}</td>
                <td>{item.credit === 0 ? '-' : item.credit.toLocaleString()}</td>
                <td>{balance === 0 ? '-' : balance.toLocaleString()}</td> {/* Display balance */}
              </tr>
            );
          })}
          <tr>
            <td><strong>Grand Total</strong></td>
            <td><strong>{totalDebit.toLocaleString()}</strong></td>
            <td><strong>{totalCredit.toLocaleString()}</strong></td>
            <td><strong>{(totalDebit - totalCredit) === 0 ? '-' : (totalDebit - totalCredit).toLocaleString()}</strong></td> {/* Display grand total balance */}
          </tr>
        </tbody>
      </table>
      <p className="note">* This should always be zero</p>
    </div>
  );
};

export default TrialBalance;
