import React, { useState } from 'react';
import './ChartOfAccounts.css'; // Import the CSS file

const ChartOfAccounts = () => {
    // State to store account entries
    const [accounts, setAccounts] = useState([]);

    // State to manage the next account code
    const [nextAccountCode, setNextAccountCode] = useState(1);

    // State to manage the current input fields for a new account
    const [newAccount, setNewAccount] = useState({
        accountCode: '',
        accountName: '',
        accountType: '',
        label: ''
    });

    // Function to handle changes in the input fields
    const handleInputChange = (field, value) => {
        setNewAccount({
            ...newAccount,
            [field]: value
        });
    };

    // Function to add the account to the table
    const addAccount = () => {
        if (newAccount.accountName && newAccount.accountType && newAccount.label) {
            const accountCodeStr = String(nextAccountCode).padStart(4, '0'); // Format the account code with leading zeros
            const accountToAdd = {
                ...newAccount,
                accountCode: accountCodeStr // Use the formatted account code
            };

            setAccounts([...accounts, accountToAdd]);
            setNextAccountCode(nextAccountCode + 1); // Increment the next account code
            setNewAccount({ accountCode: '', accountName: '', accountType: '', label: '' }); // Clear the input fields after adding
        } else {
            alert("Please fill out all fields before adding an account.");
        }
    };

    return (
        <div className="chart-of-accounts-container">
            <h1>- Chart Of Accounts -</h1>

            <div className="account-inputs">
                <div className="account-row">
                    <label>
                        Account Code:
                        <input
                            type="text"
                            placeholder="Account Code"
                            value={String(nextAccountCode).padStart(4, '0')} // Display the next account code
                            readOnly // Make the input read-only
                        />
                    </label>
                    <label>
                        Account Name:
                        <input
                            type="text"
                            placeholder="Account Name"
                            value={newAccount.accountName}
                            onChange={(e) => handleInputChange('accountName', e.target.value)}
                        />
                    </label>
                    <label>
                        Account Type:
                        <input
                            type="text"
                            placeholder="Account Type"
                            value={newAccount.accountType}
                            onChange={(e) => handleInputChange('accountType', e.target.value)}
                        />
                    </label>
                    <label>
                        Label:
                        <select
                            value={newAccount.label}
                            onChange={(e) => handleInputChange('label', e.target.value)}
                        >
                            <option value="">Select Label</option>
                            <option value="Income Statement">Income Statement</option>
                            <option value="Balance Sheet">Balance Sheet</option>
                        </select>
                    </label>
                </div>
                <button className="add-account-btn" onClick={addAccount}>Add Account</button>
            </div>

            {/* Scrollable table to display the stored accounts */}
            <div className="table-container">
                <h2>Stored Accounts</h2>
                <table className="accounts-table">
                    <thead>
                        <tr>
                            <th>Account Code</th>
                            <th>Account Name</th>
                            <th>Account Type</th>
                            <th>Label</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accounts.length === 0 ? (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>No accounts added yet.</td>
                            </tr>
                        ) : (
                            accounts.map((account, index) => (
                                <tr key={index}>
                                    <td>{account.accountCode}</td>
                                    <td>{account.accountName}</td>
                                    <td>{account.accountType}</td>
                                    <td>{account.label}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ChartOfAccounts;
