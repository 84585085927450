import React, { useState, useEffect } from "react";
import "./Payments.css";

const OutstandingPayments = () => {
  const [entityType, setEntityType] = useState("supplier"); // Entity type: supplier or company
  const [entityOptions, setEntityOptions] = useState([]); // Entity names based on type
  const [selectedEntity, setSelectedEntity] = useState(""); // Selected entity (ID)
  const [outstandingPayments, setOutstandingPayments] = useState([]); // Outstanding payments
  const [selectedPayments, setSelectedPayments] = useState([]); // Selected rows for clearing payments
  const [paymentDate, setPaymentDate] = useState(""); // Payment date
  const [paymentMethod, setPaymentMethod] = useState("Cash"); // Payment method
  const [chequeNumber, setChequeNumber] = useState(""); // Cheque number
  const [description, setDescription] = useState(""); // Payment description

  // Fetch entities (companies or suppliers) based on selected entity type
  useEffect(() => {
    const fetchEntities = async () => {
      const endpoint =
        entityType === "supplier"
          ? "https://almamzarcarwash.com:3001/api/suppliers"
          : "https://almamzarcarwash.com:3001/api/companies";

      try {
        const response = await fetch(endpoint);
        const data = await response.json();

        const formattedData = data.map((entity) => ({
          id: entity.id,
          name: entity.name,
        }));

        setEntityOptions(formattedData);
        setSelectedEntity(""); // Reset the selected entity
      } catch (error) {
        console.error(`Error fetching ${entityType} names:`, error);
        setEntityOptions([]);
      }
    };

    fetchEntities();
  }, [entityType]);

  // Fetch outstanding payments for the selected entity
  const fetchOutstandingPayments = async () => {
    if (!selectedEntity) {
      alert("Please select an entity.");
      return;
    }

    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/outstandingPayments?entityType=${entityType}&entityName=${selectedEntity}`
      );
      const data = await response.json();

      if (data.success) {
        setOutstandingPayments(data.data || []); // Handle API response properly
      } else {
        alert(data.error || "Failed to fetch outstanding payments.");
        setOutstandingPayments([]);
      }
    } catch (error) {
      console.error("Error fetching outstanding payments:", error);
      setOutstandingPayments([]);
    }
  };

  // Handle payment selection
  const handlePaymentSelection = (id) => {
    if (selectedPayments.includes(id)) {
      setSelectedPayments(selectedPayments.filter((paymentId) => paymentId !== id));
    } else {
      setSelectedPayments([...selectedPayments, id]);
    }
  };

  const handleClearPayments = async () => {
    if (selectedPayments.length === 0) {
      alert("Please select payments to clear.");
      return;
    }

    const data = {
      entityType,
      selectedPayments,
      paymentDate,
      paymentMethod,
      chequeNumber: paymentMethod === "Cheque" ? chequeNumber : null, // Include cheque number if selected
      description,
    };

    try {
      const response = await fetch("https://almamzarcarwash.com:3001/api/processPayment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text(); // Read the error message
        console.error("Server Error:", errorText);
        alert("Server Error: " + errorText);
        return;
      }

      const result = await response.json();
      if (result.success) {
        alert("Payments cleared successfully!");
        fetchOutstandingPayments(); // Refresh the outstanding payments list
      } else {
        alert(`Failed to clear payments: ${result.error}`);
      }
    } catch (error) {
      console.error("Error clearing payments:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="outstanding-payments-container">
      <h1>Outstanding Payments</h1>

      {/* Dropdowns for entity type and name */}
      <div className="entity-selector">
        <div className="form-group">
          <label htmlFor="entityType">Entity Type:</label>
          <select
            id="entityType"
            value={entityType}
            onChange={(e) => setEntityType(e.target.value)}
          >
            <option value="supplier">Supplier</option>
            <option value="company">Company</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="entityName">Entity Name:</label>
          <select
            id="entityName"
            value={selectedEntity}
            onChange={(e) => setSelectedEntity(e.target.value)}
          >
            <option value="">--Select Entity--</option>
            {entityOptions.map((entity) => (
              <option key={entity.id} value={entity.id}>
                {entity.name}
              </option>
            ))}
          </select>
        </div>

        <button className="fetch-button" onClick={fetchOutstandingPayments}>
          Show Outstanding Payments
        </button>
      </div>

{/* Table for outstanding payments */}
<div className="outstanding-payments-table-container">
  <table className="outstanding-payments-table">
    <thead>
      <tr>
        <th>Select</th>
        <th>Date</th>
        <th>Description</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      {outstandingPayments.length > 0 ? (
        outstandingPayments.map((payment) => (
          <tr key={payment.id}>
            <td>
              <input
                type="checkbox"
                checked={selectedPayments.includes(payment.id)}
                onChange={() => handlePaymentSelection(payment.id)}
              />
            </td>
            <td>{new Date(payment.date).toLocaleDateString()}</td>
            <td>{payment.description}</td>
            <td>{payment.amount}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="4" style={{ textAlign: "center" }}>
            No outstanding payments found.
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>

      {/* Total amount and clearing form */}
      <div className="total-amount">
        Total Amount:{" "}
        {outstandingPayments
          .filter((payment) => selectedPayments.includes(payment.id))
          .reduce((total, payment) => total + parseFloat(payment.amount), 0)
          .toFixed(2)}
      </div>

      <div className="payment-clearing-form">
        <div className="form-group">
          <label htmlFor="paymentDate">Payment Date:</label>
          <input
            type="date"
            id="paymentDate"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="paymentMethod">Payment Method:</label>
          <select
            id="paymentMethod"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="Cash">Cash</option>
            <option value="Bank">Bank</option>
            <option value="Cheque">Cheque</option>
          </select>
        </div>

        {/* Dynamically show cheque number field */}
        {paymentMethod === "Cheque" && (
          <div className="form-group">
            <label htmlFor="chequeNumber">Cheque Number:</label>
            <input
              type="text"
              id="chequeNumber"
              value={chequeNumber}
              onChange={(e) => setChequeNumber(e.target.value)}
              placeholder="Enter cheque number"
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description"
          />
        </div>

        <button className="clear-payments-button" onClick={handleClearPayments}>
          Clear Payments
        </button>
      </div>
    </div>
  );
};

export default OutstandingPayments;
