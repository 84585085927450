import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import './invoices.css';

const Invoices = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [services, setServices] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({ companyName: '', companyAddress: '' });
    const [loading, setLoading] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);

    useEffect(() => {
        fetch('https://almamzarcarwash.com:3001/api/companies')
            .then((response) => response.json())
            .then((data) => setCompanies(data))
            .catch((error) => console.error('Error fetching companies:', error));
    }, []);

    const handleShowAvailedServices = async () => {
        if (!selectedCompany) {
            alert('Please select a company first!');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`https://almamzarcarwash.com:3001/api/availedServices?companyId=${selectedCompany}`);
            const data = await response.json();

            if (response.ok) {
                setServices(data.services || []);
                setCompanyInfo(data.companyInfo || { companyName: 'N/A', companyAddress: 'N/A' });
            } else {
                console.error('Failed to fetch services:', data.error);
            }
        } catch (error) {
            console.error('Error fetching services:', error);
        }

        setLoading(false);
    };

    const handleGeneratePDF = () => {
        if (selectedServices.length === 0) {
            alert('Please select services to include in the invoice.');
            return;
        }
    
        const doc = new jsPDF();
    
        // Header Section
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text('Al-Mamzar Car Wash', 105, 20, { align: 'center' });
    
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text('Ajman, UAE', 105, 28, { align: 'center' });
        doc.text('TRN: 1234567890', 105, 34, { align: 'center' });
        doc.text('Tel: +971 XXXX XXXX', 105, 40, { align: 'center' });
    
        // Draw line below header
        doc.line(10, 45, 200, 45);
    
        // Invoice Title
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text('INVOICE', 105, 55, { align: 'center' });
    
        // Company and Invoice Details
        const companyDetails = companyInfo || { companyName: 'N/A', companyAddress: 'N/A' };
    
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Company: ${companyDetails.companyName}`, 10, 70);
        doc.text(`Address: ${companyDetails.companyAddress}`, 10, 76);
        doc.text(`Date: ${new Date().toLocaleDateString('en-GB')}`, 150, 70);
        doc.text(`Invoice No: ${Math.floor(Math.random() * 1000000)}`, 150, 76);

            // Draw line below details
            doc.line(10, 100, 200, 100);
    
        // Draw line below details
        doc.line(10, 85, 200, 85);
    
        // Table Headers with Adjusted Spacing
        const headers = ['Sl. No', 'Date', 'Driver Name', 'Vehicle', 'Service', 'Price (AED)'];
        const colWidths = [15, 30, 40, 30, 50, 25]; // Adjust column widths for better spacing
        let startX = 10;
        let startY = 95;
    
        doc.setFont('helvetica', 'bold');
        headers.forEach((header, index) => {
            doc.text(header, startX, startY);
            startX += colWidths[index];
        });
    
        // Table Rows
        doc.setFont('helvetica', 'normal');
        startY += 10;
        let totalAmount = 0;
    
        selectedServices.forEach((service, index) => {
            let rowX = 10; // Start at the leftmost position
            const rowY = startY + index * 10; // Increment row spacing dynamically
    
            const rowData = [
                String(index + 1),
                service.date.split('T')[0], // Format date to YYYY-MM-DD
                service.driverName || '',
                String(service.vehicle),
                service.serviceAvailed || '',
                Number(service.price).toFixed(2),
            ];
    
            rowData.forEach((data, colIndex) => {
                doc.text(data, rowX, rowY);
                rowX += colWidths[colIndex];
            });
    
            // Draw line below each row
            doc.line(10, rowY + 2, 200, rowY + 2);
            totalAmount += parseFloat(service.price || 0);
        });
    
        // Total Amount Section
        startY += selectedServices.length * 10 + 10;
        doc.setFont('helvetica', 'bold');
        doc.text('WITHOUT TAX (AED):', 120, startY);
        doc.text(totalAmount.toFixed(2), 180, startY);
    
        startY += 10;
        doc.text('TAX AMOUNT (5%):', 120, startY);
        const taxAmount = (totalAmount * 0.05).toFixed(2);
        doc.text(taxAmount, 180, startY);
    
        startY += 10;
        doc.text('TOTAL AMOUNT (AED):', 120, startY);
        const grandTotal = (totalAmount + parseFloat(taxAmount)).toFixed(2);
        doc.text(grandTotal, 180, startY);
    
        // Footer
        startY += 20;
        doc.setFont('helvetica', 'normal');
        doc.text('Thank you for your business!', 105, startY, { align: 'center' });
    
        // Save PDF
        doc.save('Invoice.pdf');
    };
    
    
    return (
        <div className="invoices-container">
            <h1>Generate Invoice</h1>
            <div className="form-group">
                <label htmlFor="company">Company:</label>
                <select
                    id="company"
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                >
                    <option value="">Select a Company</option>
                    {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                            {company.name}
                        </option>
                    ))}
                </select>
                <button onClick={handleShowAvailedServices} className="show-services-button">
                    Show Availed Services
                </button>
            </div>

            {loading ? (
                <p>Loading services...</p>
            ) : services.length > 0 ? (
                <div>
                    <table className="services-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Driver Name</th>
                                <th>Vehicle</th>
                                <th>Service Availed</th>
                                <th>Price</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map((service, index) => (
                                <tr key={index}>
                                    <td>{service.date}</td>
                                    <td>{service.driverName}</td>
                                    <td>{service.vehicle}</td>
                                    <td>{service.serviceAvailed}</td>
                                    <td>{service.price}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            onChange={() =>
                                                setSelectedServices((prev) =>
                                                    prev.includes(service)
                                                        ? prev.filter((s) => s !== service)
                                                        : [...prev, service]
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handleGeneratePDF} className="generate-pdf-button">
                        Generate Invoice
                    </button>
                </div>
            ) : (
                <p>No services found.</p>
            )}
        </div>
    );
};

export default Invoices;
