import React, { useState } from 'react';
import { signup } from '../assets';
import './Auth.css';

const Auth = ({ onVerify }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // Check credentials
        if (username === 'admin1122' && password === 'tubelight') {
            onVerify(true);  // If verified, set verify to true
        } else {
            alert('Invalid credentials. Please try again.');
            onVerify(false); // Keep verify false on failed login
        }
    }

    return (
        <div className="auth__form-container">
            <div className="auth__form-container_fields">
                <div className="auth__form-container_fields-content">
                    <p>Sign In</p>
                    <form onSubmit={handleSubmit}>
                        <div className="auth__form-container_fields-content_input">
                            <label htmlFor="username">Username</label>
                            <input
                                name="username"
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>

                        <div className="auth__form-container_fields-content_input">
                            <label htmlFor="password">Password</label>
                            <input
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <div className="auth__form-container_fields-content_button">
                            <button type="submit">Sign In</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="auth__form-container_image">
                <img src={signup} alt="sign in" />
            </div>
        </div>
    );
}

export default Auth;

