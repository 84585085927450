import React, { useState } from 'react';
import { FrontPage, Auth, Main_UI } from './components';

import './App.css';

const App = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [verify, setVerify] = useState(false); // State to track if verification is successful

  const handleLoginClick = () => {
    setIsAuth(true);
  };

  const handleVerification = (isVerified) => {
    setVerify(isVerified);
  };

  return (
    <div>
      {verify ? <Main_UI /> : isAuth ? <Auth onVerify={handleVerification} /> : <FrontPage onLoginClick={handleLoginClick} />}
    </div>
  );
};

export default App;
