import React, { useState, useEffect } from 'react';
import './JournalEntries.css';

const JournalEntries = () => {
    const [entries, setEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    };

    useEffect(() => {
        const fetchJournalEntries = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://almamzarcarwash.com:3001/api/journalEntries');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Journal Entries API Response:', data); // Debug log
                setEntries(data || []);
                setFilteredEntries(data || []); // Initialize filtered entries
            } catch (error) {
                console.error('Error fetching journal entries:', error);
                setError('Failed to fetch journal entries.');
            } finally {
                setLoading(false);
            }
        };

        fetchJournalEntries();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredEntries(
            entries.filter((entry) =>
                Object.values(entry).some(
                    (value) =>
                        value &&
                        value.toString().toLowerCase().includes(query)
                )
            )
        );
    };

    return (
        <div className="journal-entries-container">
            <h1>Journal Entries</h1>
            
            {/* Search Bar */}
            <div className="search-container">
                <span className="search-icon">&#128269;</span> {/* Unicode for magnifying glass icon */}
                <input
                    type="text"
                    placeholder="Search journal entries..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="search-bar"
                />
            </div>
            
            <div className="table-container">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                ) : (
                    <table className="entries-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.length === 0 ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>No entries found.</td>
                                </tr>
                            ) : (
                                filteredEntries.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.Date ? formatDate(entry.Date) : '-'}</td>
                                        <td>{entry.Description || '-'}</td>
                                        <td>{entry.Account || '-'}</td>
                                        <td>
                                            {entry.DebitAmount !== null && !isNaN(entry.DebitAmount)
                                                ? parseFloat(entry.DebitAmount).toFixed(2)
                                                : '-'}
                                        </td>
                                        <td>
                                            {entry.CreditAmount !== null && !isNaN(entry.CreditAmount)
                                                ? parseFloat(entry.CreditAmount).toFixed(2)
                                                : '-'}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default JournalEntries;
