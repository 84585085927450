import React from 'react';
import './FrontPage.css'; // Assuming you will create this CSS file
import videoBg from '../assets/background-video.mp4'; // Path to the video file

const FrontPage = ({ onLoginClick }) => {
  return (
    <div className="front-page-container">
      <video className="background-video" autoPlay loop muted>
        <source src={videoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="overlay">
        <div className="header">
          <button className="admin-login" onClick={onLoginClick}>
            Admin Login
          </button>
        </div>
        <div className="content">
          {/* <h1>FrontPage</h1> */}
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
