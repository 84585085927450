import React, { useState, useEffect } from 'react';
import './ServiceTransaction.css';
import './Expenses.css';
import axios from 'axios';

const Expenses = ({ expenseId, isEditing }) => {
    // State to store form data
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [expenseAmount, setExpenseAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('credit');
    const [expenseType, setExpenseType] = useState('');
    const [customExpenseType, setCustomExpenseType] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [expenseTypes, setExpenseTypes] = useState([]); // State for expense types

    // Fetch expense types from the backend
    useEffect(() => {
        const fetchExpenseTypes = async () => {
            try {
                const response = await axios.get('https://almamzarcarwash.com:3001/api/expensetypes');
                setExpenseTypes(response.data);
            } catch (error) {
                console.error('Error fetching expense types:', error);
            }
        };

        fetchExpenseTypes();
    }, []);

    // Handle expense type change
    const handleExpenseTypeChange = (event) => {
        const selectedType = event.target.value;
        setExpenseType(selectedType);
        setIsOtherSelected(selectedType === 'other');
    };

    // Handle form submission
    const handleAddExpense = async () => {
        const actualExpenseType = isOtherSelected ? customExpenseType : expenseType;

        const expenseData = {
            Date: date,
            Description: description,
            ExpenseAmount: expenseAmount ? parseFloat(expenseAmount) : null,
            PaymentMethod: paymentMethod,
            ExpenseType: actualExpenseType,
            SupplierName: supplierName ? supplierName : null,
            PaymentStatus: paymentMethod === 'credit' ? 'unpaid' : 'paid',
        };

        console.log('Sending expense data:', expenseData);

        try {
            if (isEditing && expenseId) {
                const response = await axios.put(`https://almamzarcarwash.com:3001/api/expenses/update/${expenseId}`, expenseData);
                console.log('Response from server:', response.data);
            } else {
                if (isOtherSelected && customExpenseType) {
                    await addExpenseType(customExpenseType);
                }
                const response = await axios.post('https://almamzarcarwash.com:3001/api/expenses/add', expenseData);
                console.log('Response from server:', response.data);
            }

            setDate('');
            setDescription('');
            setExpenseAmount('');
            setPaymentMethod('credit');
            setExpenseType('');
            setCustomExpenseType('');
            setSupplierName('');
        } catch (error) {
            alert('Error adding/updating expense: ' + (error.response?.data?.error || error.message));
        }
    };

    // Function to add a new expense type to the database
    const addExpenseType = async (typeName) => {
        try {
            const response = await axios.post('https://almamzarcarwash.com:3001/api/expensetypes/add', { TypeName: typeName });
            console.log('New expense type added:', response.data);
            setExpenseTypes([...expenseTypes, { TypeName: typeName, ExpenseTypeID: response.data.ExpenseTypeID }]);
        } catch (error) {
            console.error('Error adding new expense type:', error);
        }
    };

    return (
        <div>
            <div className="service-transaction-container">
                <div className="form-group">
                    <label className='date'>Date:</label>
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                </div>

                <div className="form-group">
                    <label className='des'>Description:</label>
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>

                <div className="form-group">
                    <label className='amount'>Expense Amount:</label>
                    <input type="text" value={expenseAmount} onChange={(e) => setExpenseAmount(e.target.value)} />
                </div>

                <div className="form-group">
                    <label className='payment'>Payment Method:</label>
                    <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                        <option value="cash">Cash</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className='expense'>Expense Type:</label>
                    <select value={expenseType} onChange={handleExpenseTypeChange}>
                        <option value="">Select expense type</option>
                        {expenseTypes.map((type) => (
                            <option key={type.ExpenseTypeID} value={type.TypeName}>
                                {type.TypeName}
                            </option>
                        ))}
                        <option value="other">Other</option>
                    </select>
                    {isOtherSelected && (
                        <input
                            type="text"
                            placeholder="Enter custom expense type"
                            value={customExpenseType}
                            onChange={(e) => setCustomExpenseType(e.target.value)}
                        />
                    )}
                </div>

                <div className="form-group">
                    <label className='supplier'>Supplier Name:</label>
                    <input type="text" value={supplierName} onChange={(e) => setSupplierName(e.target.value)} />
                </div>

                <div className="button-container">
                    <button onClick={handleAddExpense} className="add-expense-btn">
                        Add Expense
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Expenses;
